import React, { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice, Order, S3ItemReference } from '@alliance-disposal/transport-types';
import { ImageDialog } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Accordion, Button, Popover, Tooltip } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { DocumentDuplicateIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { format, startOfYear } from 'date-fns';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { twMerge } from 'tailwind-merge';
import { durationUnitTypes, haulerBillPaymentMethods, routes } from '../../utils';
import { dayAbbOrder } from '../../utils';
import { type VendorOrderStats, getVendorOrderStats } from '../../utils/';
import { paymentTerms } from '../../utils/shared-types';
import HaulerUpdate from '../HaulerUpdate';
import { InternalTicket } from '../InternalTicket';
import Loading from '../Loading';
import StarRating from '../StarRating';
import HaulerPricingDetails from './HaulerPricingDetails';
import HaulerContactDetails from './hauler-contact-details';

export interface HaulerDetailsProps {
    id: string;
    withHaulerPricingDetails?: boolean;
}

const HaulerDetails = ({ id, withHaulerPricingDetails }: HaulerDetailsProps) => {
    const client = useWaysteClient();
    const { setShowToast, godModeActive } = useSourContext();
    const [showUpdateHauler, setShowUpdateHauler] = useState<boolean>(false);
    const [showHoursOfOperation, setShowHoursOfOperation] = useState<boolean>(false);
    const [hauler, setHauler] = useState<Hauler.HaulerWithAapTransport | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showFiles, setShowFiles] = useState(false);
    const [files, setFiles] = useState<S3ItemReference[]>(hauler?.files || []);
    const [GMV, setGMV] = useState<Invoice.GMVSummary[]>([]);
    const [hasWaysteActivity, setHasWaysteActivity] = useState<boolean>(true);
    const [haulerOrderStats, setHaulerOrderStats] = useState<VendorOrderStats[0]>();

    const getHauler = async () => {
        setIsLoading(true);
        try {
            if (!id) throw new Error('No ID provided');

            const [response, waysteActivity, GMVSummary] = await Promise.all([
                client.vendorService().adminPortal.fetch(id), //fetch hauler info
                client.vendorService().adminPortal.activity.query({ haulerID: id }), //fetch wayste activity
                client.invoice().adminPortal.gmvSummary({ vendorID: id, orderDateAfter: startOfYear(new Date()).toISOString() }), //fetch GMV
            ]);
            setHauler(response);
            setFiles(response.files || []);
            if (waysteActivity.results.length === 0) setHasWaysteActivity(false);
            setGMV(GMVSummary);
        } catch (error) {
            console.warn(error);
            setShowToast({
                severity: 'error',
                message: 'Error Fetching Hauler Information',
            });
        } finally {
            setIsLoading(false);
        }
        // extracted elasticsearch query out do make local development either cause it doesn't work locally
        try {
            const stats = await getVendorOrderStats([id]); //elasticsearch query
            setHaulerOrderStats(stats[id]);
        } catch (error) {
            console.warn(error);
            setShowToast({
                severity: 'error',
                message: 'Error Fetching Hauler Stats',
            });
        }
    };

    const handleFileChanges = async (newFiles: S3ItemReference[]) => {
        if (!hauler) return;
        try {
            await client.vendorService().adminPortal.update(hauler.id, { files: newFiles });
            setFiles(newFiles);
        } catch (error) {
            console.warn(error);
            setShowToast({
                severity: 'error',
                message: 'Error Updating Files',
            });
        }
    };

    useEffect(() => {
        getHauler();
    }, [id]);

    if (isLoading) return <Loading />;

    if (!hauler) return <div>No hauler exists</div>;

    const colOne = [
        {
            label: 'Rating',
            value: <StarRating rating={hauler.rating} />,
        },
        {
            label: 'Billing Address',
            value: formatServiceAddress(hauler.billingAddress),
        },
        {
            label: 'Sourgum Vendor #',
            value: hauler.sourgumVendorNumber,
        },
        { label: 'Vendor Added', value: format(new Date(hauler.metadata.createdAt), 'MM/dd/yy') },
        // { label: 'SW Number', value: hauler.swNumber }, TODO changes over to licenses
        { label: 'Notes', value: hauler.notes },
    ];

    const colTwo = [
        {
            label: 'Default Payment Method',
            value: haulerBillPaymentMethods[hauler.defaultPaymentMethod as keyof typeof haulerBillPaymentMethods],
        },
        {
            label: 'Default Payment Term',
            value: paymentTerms[hauler.defaultPaymentTerm as keyof typeof paymentTerms] || '',
        },
        {
            label: 'Base Rent Period',
            value:
                !hauler.waysteRentalPeriod?.value || hauler.defaultRentPeriod?.value === hauler.waysteRentalPeriod.value ? (
                    hauler.defaultRentPeriod?.value
                ) : (
                    <div className="flex items-center gap-x-1">
                        <span>{hauler.defaultRentPeriod?.value}</span>
                        <Tooltip text={`The Wayste rental period is ${hauler.waysteRentalPeriod?.value}`}>
                            <ExclamationTriangleIcon className="size-5 text-yellow-500" />
                        </Tooltip>
                    </div>
                ),
        },
        {
            label: 'Rental Period Units',
            value: hauler.defaultRentPeriod?.unit ? durationUnitTypes[hauler.defaultRentPeriod.unit as keyof typeof durationUnitTypes] : '',
        },
        {
            label: 'Rental Extension Fee',
            value:
                !hauler.waysteRentalExtensionFee || hauler.rentExtensionFee === hauler.waysteRentalExtensionFee ? (
                    moneyFormatter(hauler.rentExtensionFee)
                ) : (
                    <div className="flex items-center gap-x-1">
                        <span>{moneyFormatter(hauler.rentExtensionFee)}</span>
                        <Tooltip text={`The Wayste rental extension fee is ${moneyFormatter(hauler.waysteRentalExtensionFee)}`}>
                            <ExclamationTriangleIcon className="size-5 text-yellow-500" />
                        </Tooltip>
                    </div>
                ),
        },
        {
            label: 'Hours of Operation',
            value: (
                <div>
                    <Accordion
                        controlOpen={{ open: showHoursOfOperation, onClick: (isOpen) => setShowHoursOfOperation(!isOpen) }}
                        title={showHoursOfOperation ? 'Hide' : 'Show'}
                        hideChevron
                        buttonClassName="bg-transparent p-0 text-wayste-blue-400"
                    >
                        {dayAbbOrder.map((key: string) => {
                            const hours = hauler.hoursOfOperation[key as keyof typeof hauler.hoursOfOperation];

                            if (!hours.open)
                                return (
                                    <div key={key}>
                                        <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{key}: </span>
                                        Closed
                                    </div>
                                );

                            return (
                                <div key={key}>
                                    <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{key}: </span>
                                    {hours.start ? format(new Date(hours.start), 'hh:mm aaa') : ''} -{' '}
                                    {hours.end ? format(new Date(hours.end), 'hh:mm aaa') : ''}
                                </div>
                            );
                        })}
                    </Accordion>
                </div>
            ),
        },
    ];

    const colThree = [
        {
            label: 'Order Count YTD',
            value: GMV.length,
        },
        {
            label: 'Sourgum Revenue YTD',
            value: GMV.length > 0 ? moneyFormatter(GMV.reduce((a, b) => a + (b?.revenue || 0), 0)) : '',
        },
        {
            label: 'Vendor Costs YTD',
            value: GMV.length > 0 ? moneyFormatter(GMV.reduce((a, b) => a + (b?.cost || 0), 0)) : '',
        },
        {
            label: 'Average Take Rate YTD',
            value:
                GMV.length > 0 ? `${Math.round((GMV.reduce((a, b) => a + (b?.takeRate || 0), 0) / GMV.length) * 10000) / 100}%` : 'No Data',
        },
        {
            label: 'Number of Active Orders',
            value: haulerOrderStats?.orderCount.total ? (
                <Popover
                    text={
                        <div>
                            {haulerOrderStats.orderCount.statusBreakdown.map(({ key, doc_count }) => (
                                <div key={key}>{`${Order.SourgumOrderStatusLabels[key]}: ${doc_count}`}</div>
                            ))}
                        </div>
                    }
                >
                    {haulerOrderStats.orderCount.total}
                </Popover>
            ) : (
                0
            ),
        },
        {
            label: 'Last Order',
            value: haulerOrderStats?.lastOrder.metadata.createdAt ? (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={routes.orders.details(haulerOrderStats?.lastOrder?.id || '')}
                    className="text-wayste-blue-400"
                >
                    {format(new Date(haulerOrderStats?.lastOrder.metadata.createdAt), 'MM/dd/yy')}
                </a>
            ) : (
                'N/A'
            ),
        },
    ];

    return (
        <div className="flex w-full flex-col gap-y-6">
            <div className="flex flex-col gap-y-3">
                <div className="flex items-center justify-between">
                    <div
                        className={twMerge(
                            'flex items-end gap-x-1.5 px-4',
                            !hauler.active || !hauler.isSourgumPartner ? 'text-red-500' : 'text-gray-800',
                        )}
                    >
                        {(!hauler.active || !hauler.isSourgumPartner) && <ExclamationTriangleIcon className="size-7" />}
                        <div className="text-xl font-semibold leading-7 tracking-tight">{hauler.name}</div>
                        {(!hauler.isSourgumPartner && ' - Not A Sourgum Partner') || (!hauler.active && ' - Inactive')}
                    </div>
                    <div className="flex items-center gap-x-4">
                        <Button
                            className="btn-dark-grey-outlined"
                            endIcon={<DocumentIcon className="size-5 md:size-4" />}
                            collapse
                            onClick={() => setShowFiles(true)}
                        >
                            Manage Files
                        </Button>
                        <Tooltip
                            text={
                                <p className="text-lg">
                                    {hasWaysteActivity
                                        ? 'This vendor has activity on Wayste and cannot be signed up again.\n'
                                        : 'This vendor has no activity on Wayste.\n'}
                                    <b>WARNING</b>: This will only work for Vendors who have <b>NEVER</b> signed into wayste and the user
                                    that uses this link will become the the administrator of the Vendors account. <br />
                                    <br />
                                    <span>Please be careful who you send this link to.</span>
                                </p>
                            }
                        >
                            <Button
                                endIcon={<DocumentDuplicateIcon className="size-4" />}
                                collapse
                                className="btn-dark-grey-outlined"
                                disabled={hasWaysteActivity && !godModeActive}
                                onClick={() => {
                                    const link = `https://app.wayste.com/sign-up?token=${hauler.id}`;
                                    navigator.clipboard.writeText(link);
                                    setShowToast({
                                        severity: 'success',
                                        message: 'Copied sign up link to clipboard',
                                    });
                                }}
                            >
                                Copy Sign Up Link
                            </Button>
                        </Tooltip>
                        <Button
                            endIcon={<HiOutlinePencilAlt className="size-5 md:size-4" />}
                            onClick={() => setShowUpdateHauler(true)}
                            className="btn-primary"
                            collapse
                        >
                            Edit Vendor
                        </Button>
                    </div>
                </div>
                {!hauler.isSourgumPartner && (
                    <p className="px-4 text-sm text-red-500">This vendor is not in the marketplace network. DO NOT CONTACT FOR PRICING.</p>
                )}
                {/* Details Card Start */}
                <div className="flex flex-col gap-y-4 overflow-auto rounded-lg border border-gray-300 bg-[#fcfcfd] p-4 text-sm shadow">
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                        <div className="h-full lg:border-r lg:pr-4">
                            <div className="grid h-fit grid-cols-3 gap-2">
                                {colOne.map((item) => (
                                    <React.Fragment key={item.label}>
                                        <div>
                                            <span className="text-gray-500">{item.label}:</span>
                                        </div>
                                        <div className="col-span-2">{item.value}</div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className="h-full lg:border-r lg:pr-4">
                            <div className="grid h-fit grid-cols-3 gap-2">
                                {colTwo.map((item) => (
                                    <React.Fragment key={item.label}>
                                        <div>
                                            <span className="text-gray-500">{item.label}:</span>
                                        </div>
                                        <div className="col-span-2">{item.value}</div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className="h-full">
                            <div className="grid h-fit grid-cols-3 gap-2">
                                {colThree.map((item) => (
                                    <React.Fragment key={item.label}>
                                        <div>
                                            <span className="text-gray-500">{item.label}:</span>
                                        </div>
                                        <div className="col-span-2">{item.value}</div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Details Card End */}
            </div>
            <HaulerContactDetails
                hauler={hauler}
                hasWaysteActivity={hasWaysteActivity}
                setHasWaysteActivity={setHasWaysteActivity}
                onContactsUpdated={() => getHauler()}
            />
            <InternalTicket entityID={hauler.id} entityType="sourgum-vendor" />
            {withHaulerPricingDetails && <HaulerPricingDetails hauler={hauler} />}
            {/* Dialogs */}
            <HaulerUpdate
                open={showUpdateHauler}
                onBackButtonClick={() => setShowUpdateHauler(false)}
                onSubmitSuccessful={() => {
                    setShowUpdateHauler(false);
                    getHauler();
                }}
                hauler={hauler}
            />
            <ImageDialog
                open={showFiles}
                entityID={hauler.id}
                imageReferences={files}
                onClose={() => setShowFiles(false)}
                setImageReferences={handleFileChanges}
                folderName={`hauler/${hauler.id?.toString() ?? ''}`}
                showToVendor={'ONLY'}
            />
        </div>
    );
};

export default HaulerDetails;
